@import "../../../assets/styles/base";

.btn {
  background: linear-gradient(179.94deg, $main 0.05%, $main 113.22%) !important;
  border-radius: 10px;
  cursor: pointer;
  width: 100%;

  &.transparent-btn {
    background: rgba(189, 189, 189, 0.5) !important;
    /* Boton */

    border: 2px solid #00AA80;
    box-sizing: border-box;
    border-radius: 10px;
  }

  &.MuiButtonBase-root {
    margin-bottom: 13px;
    text-transform: capitalize;
    border-radius: 10px;
    height: 40px;

    &.btn--from-modal {
      border-radius: 0;
    }

    &.MuiButton-contained {
      transition: none;
      &:hover {
        background: $aquamarine !important;
      }
    }
  }

  span {
    color: $light-white;
    font-style: normal;
    font-weight: $weight-bold;
    font-size: 16px;
    line-height: 20px;
  }

  &--blue {
    background: linear-gradient(to right, $light-blue, $light-blue) !important;
  }

  a {
    text-decoration: none;
  }

  &--small {
    span {
      font-size: 12px;
    }

    .MuiButton-label {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
    }
  }

  &--disabled {
    cursor: default;
    background: $light-gray !important;
    border-radius: 10px;

    .MuiButton-label {
      color: $white;
    }
  }
}

.btn--disabled{
  background-color: #BDBDBD !important;
  cursor: not-allowed !important;
}
