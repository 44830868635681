@import "../../../assets/styles/base";

.app__main {
    background-color: #F4F4F4;
    min-height: 100vh;
}

.app__content {
    min-height: calc(100vh - 70px);
    min-width: 600px;
    padding: 0px 15px 50px 15px;
    display: flex;
    align-items: center;
    flex-direction: column;

    @media (max-width:  768px) {
        min-width: 100%;
    }

    form {
        width: 100%;
    }
}

