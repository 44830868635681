
// COLORS
$border-gray:#BDBDBD;
$black: #000000;
$dark-blue: #0A1E43;
$dark-gray: #929292;
$gray: #4F4F4F;
$green: #00AA80;
$ligh-green: #2CAD6E;
$light-blue: #00A0DF;
$light-gray: #E0E0E0;
$light-white: #FAFAFA;
$medium-gray: #828282;
$dark: #3A3F4D;
$orange: #D55C38;
$pink: #CB4089;
$purple: #330033;
$violet: #9431C2;
$yellow: #FFFF00;
$white: #FFFFFF;
$aquamarine:#25B6BF;
$red:#E73A3B;
$hyperlink: #007A8C;
$main: $green;

// FONT WEIGHT
$weight-megabold: 700;
$weight-extrabold: 600;
$weight-bold: 500;
$weight-medium: 400;
$weight-light: 300;
$weight-text-bold: 600;

//BREAKPOINTS
$sm: 320px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;