@import "../../../assets/styles/variables";

.loading_spin__container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;

  position: fixed;
  z-index: 9999;
  top:0px;
  left: 0px;
  background-color: #F4F4F4;
  width: 100vw;

  span {
    width: 60px !important;
    height: 60px !important;
  }

  svg {
    color: $main;
  }
}

.loading_spin_logo {
  position: absolute;
  top: 60px;
  text-align: center;
  p {
    color: #929292;
  }
}

.stop-scrolling {
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}