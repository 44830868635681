@import "../../../assets/styles/base";

.title {
  color: $aquamarine;
  font-style: normal;
  font-weight: $weight-extrabold;
  font-size: 16px;
  line-height: 20px;
  text-align: start;
  &--secondary {
    &--black {
      font-weight: $weight-extrabold;
      color: $gray !important;
    }
  }
  &--disabled {
    color: $medium-gray;
  }

}

.title-center-cards{
  text-align: center;
  padding-top: 27px;
}